import React, {useState, useEffect, useCallback} from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import {Spinner, Form, Tab, Tabs, Button} from 'react-bootstrap'
import MaskedInput from 'react-maskedinput'
import Swal from 'sweetalert2'
import utils from '../services/utils';
import '../App.scss';

import Header from '../components/header';
import Nav from '../components/nav';
import * as Produtos from '../components/produtos';
// import Footer from '../components/footer';
import { apiURL } from '../services/api';

function Order() {
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false)
	const [address, setAddress] = useState()
    const [cliente, setCliente] = useState()
    const [escola, setEscola] = useState()
	const [pedido, setPedido] = useState()
	const [token, setToken] = useState()
	const [tickets, setTickets] = useState([])
    const [total, setTotal] = useState(0)
    const [status, setStatus] = useState(0)
    const [cupom, setCupom] = useState()
    let navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {

            var t = token
            var e = escola
            var p = pedido
            if(token === undefined) {
                t = localStorage.getItem('TOKEN');
                p = localStorage.getItem('PEDIDO_COMPRA');
                e = JSON.parse(localStorage.getItem('ESCOLA'));
                setToken(t)
                setEscola(e)
                setPedido(p)
            }
            // get the data from the api
            const data = await apiURL.get('pedido?status=1&pedido='+p, { headers: { Authorization: 'Bearer ' + t }})
            // convert data to json
            setTickets(
                data.data.ticket.map((x) => {
                    return { ...x };
                })
            );
            setTotal(data.data.total);
            setStatus(data.data.status);
            if(data.data?.cupom !== null)
                setCupom(data.data?.cupom);
        }

        fetchData()
            .catch(console.error);
    }, [token, escola, pedido])

    return (
		<>
            <div className="container main">
                <Header />
                <Nav active="Pedido Completo" />
                <div className="row order p-5">
                    <div className="col-md-8">
                        {
                            status?.forma === 'PIX' ?
                            <>
                                <p>Você acabou de fazer o pagamento usando o {status?.forma}.</p>
                                <p>Assim que o seu banco confirmar o pagamento, seu pedido será processado, e você receberá um e-mail.</p>
                            </>
                            :
                            <>
                                {
                                    status?.forma === 'Cartão de Crédito' ?
                                    <p>Você acabou de fazer o pagamento em {status?.parcelas}x usando o {status?.forma}.</p>
                                    : status?.forma === 'Cartão de Débito' ?
                                    <p>Você acabou de fazer o pagamento usando o {status?.forma}.</p>
                                    :
                                    <p>Ingressos de Cortesia.</p>
                                }
                                {
                                    status?.forma !== 'CORTESIA' &&
                                    <p>Assim que a operadora do seu cartão confirmar o pagamento, seu pedido será processado, e você receberá um e-mail.</p>
                                }
                            </>
                        }
                        <h4 className="mt-5">Detalhes do pedido</h4>
                        {
                            tickets.length > 0 &&
                            <div className="cart row">
                                <div className="produtos noBorder col-12">
                                    <Produtos.Header />
                                    {
                                        tickets.map((ticket, index) => {
                                            return (
                                                <Produtos.Produto key={index} index={index} item={ticket} edit={false} />
                                            )
                                        })
                                    }
                                </div>
                                <hr />
                                <div className="resumoCart col-12">
                                    <Produtos.Resumo tickets={tickets} cupom={cupom}/>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="col-md-4">
                        <div class="order-box">
                            {
                                status?.status === 'APPROVED' ?
                                <p class="success"><strong>Obrigado. Seu pedido foi recebido.</strong></p>
                                :
                                <p class="danger"><strong>Obrigado. Seu pedido foi recebido, e esta em análise</strong></p>
                            }
				            <ul class="order_details">
                                <li>
                                    Número do pedido: <strong>#{status?.idPedido}</strong>
                                </li>
                                <li>
                                    Data: <strong>{status?.data}</strong>
                                </li>
                                <li>
                                    Total: <strong>{total}</strong>
                                </li>
                                <li>
							        Método de pagamento: <strong>{status?.forma}</strong>
						        </li>
				            </ul>
			            </div>
                    </div>
                </div>
            </div>
		</>
    );
}
  
export default Order;