import React, {useState, useEffect} from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import './style.scss';

function Header() {

    return (
		<>
			<div className="header row">
				<div className="col-md-6 col-12">
					<p>PRODUTO</p>
				</div>
				<div className="col-md-2 col-12">
					<p>PREÇO</p>
				</div>
				<div className="col-md-2 col-12">
					<p>QUANTIDADE</p>
				</div>
				<div className="col-md-2 col-12">
					<p>SUBTOTAL</p>
				</div>
			</div>
		</>
    );
}
  
export default Header;