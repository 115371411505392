import React, {useState, useEffect} from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import {Image} from 'react-bootstrap'
import './style.scss';

function Header() {
	const [nome, setNome] = useState(localStorage.getItem('NOME_PESQUISA'))
	const { grupo } = useParams();
    const [logo, setLogo] = useState('https://convencaoanglo2023.livrariaeducacional.com.br/images/logo-anglo.png');
    let navigate = useNavigate();

    return (
		<>
            <div className="container-fluid menu">
                <div className="row navbar-brand">
                    <a className="" href="/">
                        <Image src={logo} fluid={false} alt={"Livraria Educacional"} />
                    </a>
                </div>
                <hr />
            </div>
		</>
    );
}
  
export default Header;