import React, {useState, useEffect, useCallback} from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import {Button} from 'react-bootstrap';
import Swal from 'sweetalert2'
import Utils from '../../../services/utils';
import './style.scss';

function Produto({handle, item, index, edit = true}) {
    // const [quantidade, setQuantidade] = useState(item.qtd)

    const changeQtd = useCallback((newQtd, tipo) => {
        if(newQtd === 0) {
            Swal.fire({
                title: 'Remover Ingresso',
                text: 'Deseja realmente remover este ingresso do carrinho?',
                icon: 'warning',
                showDenyButton: true,
                confirmButtonText: 'Sim',
                denyButtonText: 'Não',
            }).then((result) => {
                if (result.isConfirmed) {
                    // setQuantidade(newQtd)
                    handle(newQtd, tipo, index)
                }
            })
        } else {
            // setQuantidade(newQtd)
            handle(newQtd, tipo, index, item.camas)
        }
    }, [handle, index])

    return (
		<>
            <div className="produto row d-flex flex-row align-items-center mb-3">
                <div className="col-md-6 col-12">
                    <p className="mb-2 mt-2">{item.nome}</p>
				</div>
				<div className="col-md-2 col-12">
                    <p className="mb-2 mt-2">{Utils.formatMoney(parseFloat(item.valor))}</p>
				</div>
                {
                    edit ?
				    <div className="col-md-2 col-12 d-flex align-items-center justify-content-between">
                        <Button onClick={() => (changeQtd(parseInt(item.qtd) - 1, 'remove'))}>-</Button>
                        <p className="mb-2 mt-2">{item.qtd}</p>
                        <Button onClick={() => (changeQtd(parseInt(item.qtd) + 1, 'add'))}>+</Button>
				    </div>
                    :
				    <div className="col-md-2 col-12 d-flex align-items-center justify-content-start">
                        <p className="mb-2 mt-2 text-left">{item.qtd}</p>
				    </div>

                }
				<div className="col-2">
                    <p className="mb-2 mt-2">{Utils.formatMoney(parseFloat(item.valor)*parseInt(item.qtd))}</p>
				</div>
            </div>
		</>
    );
}
  
export default Produto;