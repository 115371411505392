import React, {useState, useEffect, useCallback} from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import {Spinner, Button} from 'react-bootstrap'
import Swal from 'sweetalert2'
import { v4 as uuid } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import '../App.scss';

import Header from '../components/header';
import Nav from '../components/nav';
import * as Produtos from '../components/produtos';
import CodigoEscola from '../components/forms/codigoEscola';
// import Footer from '../components/footer';
import { apiURL } from '../services/api';

function Cart() {
    const { ingresso } = useParams();
    const [loading, setLoading] = useState(false)
    const [loadingPage, setLoadingPage] = useState(false)
    const [tickets, setTickets] = useState([]);
    const [cupom, setCupom] = useState();
    const [escola, setEscola] = useState();
    const [token, setToken] = useState();
    const [liberado, setLiberado] = useState(true);
    let navigate = useNavigate();

    useEffect(() => {
        if(localStorage.getItem('TOKEN') === null) {
            const unique_id = uuid();
            localStorage.setItem("TOKEN", unique_id)
            setToken(unique_id);
        }
        if(localStorage.getItem('ESCOLA') !== null) {
            setEscola(JSON.parse(localStorage.getItem('ESCOLA')));
        }
        getLimite();
        if(ingresso) {
            getTicket()
        } else {
            getPedido()
        }
    }, [ingresso])

    const getLimite = useCallback(async () => {
        try {
            await apiURL.get('limite')
            .then(function (response) {
                if(response.data.disponivel > 0) {
                    setLiberado(true)
                } else {
                    setLiberado(false)
                }
            })
            .catch(function (error) {
            })
        } catch (error) {
            
        }
    },[]);

    const getTicket = useCallback(async () => {
        try {
            tickets.forEach(element => {
                if(element.id === ingresso) {
                    throw new Error("Break the loop.")
                }
            });
            document.body.classList.add('overflow-hidden');
            var t = token
            if(token === undefined) {
                t = localStorage.getItem('TOKEN');
                setToken(t)
            }
            await apiURL.get('ticket?ticket='+ingresso, { headers: { Authorization: 'Bearer ' + t }})
            .then(function (response) {
                if(response.data.success) {
                    setTickets(
                        response.data.ticket.map((x) => {
                            return { ...x };
                        })
                    );
                    document.body.classList.remove('overflow-hidden');
                    setLoadingPage(false)
                } else {
                    document.body.classList.remove('overflow-hidden');
                    setLoadingPage(false)
                    Swal.fire({
                        title: 'Ops!',
                        text: response.data.message,
                        icon: 'info',
                        confirmButtonText: 'Fechar'
                    })
                }
            })
            .catch(function (error) {
                document.body.classList.remove('overflow-hidden');
                setLoadingPage(false)
                Swal.fire({
                    title: 'Ops!',
                    text: 'Algo deu errado tente novamente',
                    icon: 'info',
                    confirmButtonText: 'Fechar'
                })
            })
        } catch (error) {
            
        }
    },[ingresso, tickets, token, escola])
    
    const getPedido = useCallback(async () => {
        try {
            tickets.forEach(element => {
                if(element.id === ingresso) {
                    throw new Error("Break the loop.")
                }
            });
            document.body.classList.add('overflow-hidden');
            var t = token
            if(token === undefined) {
                t = localStorage.getItem('TOKEN');
                setToken(t)
            }
            await apiURL.get('pedido', { headers: { Authorization: 'Bearer ' + t }})
            .then(function (response) {
                if(response.data.success) {
                    setTickets(
                        response.data.ticket.map((x) => {
                            return { ...x };
                        })
                    );
                    document.body.classList.remove('overflow-hidden');
                    setLoadingPage(false)
                } 
                else {
                    document.body.classList.remove('overflow-hidden');
                    setLoadingPage(false)
                    Swal.fire({
                        title: 'Ops!',
                        text: response.data.message,
                        icon: 'info',
                        confirmButtonText: 'Fechar'
                    })
                }
            })
            .catch(function (error) {
                document.body.classList.remove('overflow-hidden');
                setLoadingPage(false)
                Swal.fire({
                    title: 'Ops!',
                    text: 'Algo deu errado tente novamente',
                    icon: 'info',
                    confirmButtonText: 'Fechar'
                })
            })
        } catch (error) {
            
        }
    },[ingresso, tickets, token])

    const handleProduto = useCallback(async (qtd, tipo, index, camas) => {
        if(escola === undefined) {
            Swal.fire({
                title: 'Ops!',
                text: 'Insira o código da sua escola para poder adicionar mais produtos no carrinho',
                icon: 'info',
                confirmButtonText: 'Fechar'
            })
        } else {
            var qtdAdd = 0;
            tickets.forEach(element => {
                qtdAdd = qtdAdd + parseInt(element.qtd * element.camas);
            });
            if(qtdAdd < parseInt(escola.disponiveis) || tipo === 'remove') {
                tickets[index].qtd = qtd
            } else {
                Swal.fire({
                    title: 'Ops!',
                    text: 'Sua escola já atingiu o limite de ingressos disponíveis',
                    icon: 'info',
                    confirmButtonText: 'Fechar'
                })
            }
            if(qtd === 0) {
                var id = tickets[index].id
                tickets.splice(index, 1);
                await apiURL.delete('ticket?ticket='+id, { headers: { Authorization: 'Bearer ' + token }})
            }
           setTickets([...tickets]);
        }
    },[tickets, escola, token])

    const handleCupom = useCallback((c) => {
        setCupom(c)
    },[])
    
    const handleCodigo = useCallback(async (e, p) => {
        if(p === 1) {
            localStorage.setItem('PEDIDO_COMPRA',e.naoPagos)
            navigate('/checkout');
        } else if(p === 2) {
            setLoadingPage(true)
            document.body.classList.add('overflow-hidden');
            var t = token
            if(token === undefined) {
                t = localStorage.getItem('TOKEN');
                setToken(t)
            }
            await apiURL.delete('pedido?pedido=' + e.naoPagos + '&escola=' + e.id, { headers: { Authorization: 'Bearer ' + t }})
            .then(function (response) {
                if(response.data.success) {
                    setTimeout(() => {
                        document.body.classList.remove('overflow-hidden');
                        setLoadingPage(false)
                    }, 3000);
                } 
                else {
                    document.body.classList.remove('overflow-hidden');
                    setLoadingPage(false)
                    Swal.fire({
                        title: 'Ops!',
                        text: response.data.message,
                        icon: 'info',
                        confirmButtonText: 'Fechar'
                    })
                }
            })
            .catch(function (error) {
                document.body.classList.remove('overflow-hidden');
                setLoadingPage(false)
                Swal.fire({
                    title: 'Ops!',
                    text: 'Algo deu errado tente novamente',
                    icon: 'info',
                    confirmButtonText: 'Fechar'
                })
            })
        }
        setEscola(e)
    },[token])

    const handleNext = useCallback(async () => {
        const qtdDisponivel = await getEscola();
        if(qtdDisponivel) {
            var qtdAdd = 0;
            tickets.forEach(element => {
                qtdAdd = qtdAdd + parseInt(element.qtd * element.camas);
            });
            if(qtdAdd > parseInt(escola.disponiveis) || qtdAdd > qtdDisponivel) {
                Swal.fire({
                    title: 'Ops!',
                    text: 'Quantidade de ingressos no carrinho superior ao limite disponível para a escola, favor ajustar.',
                    icon: 'warning',
                    confirmButtonText: 'Fechar'
                })
            } 
            else {
                const data = {
                    tickets,
                    escola,
                    cupom
                }
                await apiURL.put('ticket', data, { headers: { Authorization: 'Bearer ' + token }})
                .then(function (response) {
                    if(response.data.success) {
                        navigate('/register')
                    } else {
                        Swal.fire({
                            title: 'Ops!',
                            text: 'Algo deu errado tente novamente',
                            icon: 'info',
                            confirmButtonText: 'Fechar'
                        })
                    }
                })
                .catch(function (error) {
                    Swal.fire({
                        title: 'Ops!',
                        text: 'Algo deu errado tente novamente',
                        icon: 'info',
                        confirmButtonText: 'Fechar'
                    })
                })
            }
        } else if(qtdDisponivel === 0) {
            Swal.fire({
                title: 'Ops!',
                text: 'Quantidade de ingressos no carrinho superior ao limite disponível para a escola, favor ajustar.',
                icon: 'warning',
                confirmButtonText: 'Fechar'
            })
        }
    }, [tickets, escola, cupom, token, navigate])

    const getEscola = useCallback(async () => {
        setLoading(true)
        var t = localStorage.getItem('TOKEN');
        const dataRetorno = await apiURL.get('escola?codigo='+escola.codigo, { headers: { Authorization: 'Bearer ' + t }})
        .then(async function (response) {
            if(response.data.success) {
                setEscola(response.data.escola)
                localStorage.setItem("ESCOLA", JSON.stringify(response.data.escola))
                let p = 0;
                if(!response.data.escola.pago) {
                    const dataRetorno2 = await Swal.fire({
                        title: 'Pedido Encontrado!',
                        text: 'Existe um pedido não pagos para esta escola deseja pagar?',
                        icon: 'info',
                        confirmButtonText: 'Sim, pagar',
                        showDenyButton: true,
                        showCloseButton: true,
                        showCancelButton: true,
                        confirmButtonColor: '#28a745',
                        denyButtonText: 'Não, excluir esse pedido',
                        cancelButtonText: 'Fechar e continuar novo pedido',
                    }).then(async function (result) {
                        if (result.isConfirmed) {
                            p = 1;
                        } else if(result.isDenied) {
                            p = 2;
                            setTimeout(() => {
                                apiURL.get('escola?codigo='+escola.codigo, { headers: { Authorization: 'Bearer ' + t }})
                                .then(function (response) {
                                    if(response.data.success) {
                                        setEscola(response.data.escola)
                                        handleCodigo(response.data.escola, 0)
                                    }
                                })
                            }, 3000);
                        } else {
                            return response.data.escola.disponiveis
                        }
                        handleCodigo(response.data.escola, p)
                    })
                    return dataRetorno2
                } else {
                    return response.data.escola.disponiveis
                }
            } else {
                setLoading(false)
                Swal.fire({
                    title: 'Ops!',
                    text: response.data.message,
                    icon: 'info',
                    confirmButtonText: 'Fechar'
                })
                return false;
            }
        })
        .catch(function (error) {
            setLoading(false)
            Swal.fire({
                title: 'Ops!',
                text: 'Algo deu errado tente novamente',
                icon: 'info',
                confirmButtonText: 'Fechar'
            })
        })
        return dataRetorno
    }, [escola])

    return (
		<>
            {
                loadingPage &&
                <div className="loadingPage">
                    <Spinner animation="grow" variant="primary" />
                </div>
            }
            <div className="container main">
                <p className="d-flex align-items-center backSite">
                    <FontAwesomeIcon icon={faChevronLeft} className=" mt-4" />
                    <a className="ms-2 mt-4" href="https://convencaoanglo2023.com.br">Voltar para o site</a>
                </p>
                <Header />
                <Nav active="Carrinho de Compras" />
                <CodigoEscola handle={handleCodigo} school={escola} />
                {
                    tickets.length > 0 &&
                    <div className="cart row p-5">
                        <div className="produtos col-lg-8 col-12">
                            <Produtos.Header />
                            {
                                tickets.map((ticket, index) => {
                                    return (
                                        <Produtos.Produto key={index} index={index} item={ticket} handle={handleProduto} />
                                    )
                                })
                            }
                        </div>
                        <div className="resumoCart col-lg-4 col-12">
                            <Produtos.Resumo tickets={tickets} cupom={cupom} />
                            <div className="d-grid gap-2 mb-4">
                                <Button onClick={() => handleNext()}>Continuar para dados pessoais</Button>
                            </div>
                            <hr />
                            <Produtos.Cupom handle={handleCupom} />
                        </div>
                    </div>
                }
                <div className="row">
                    <div className="col-12">
                        <p className="text-center">Cancelamentos de pedidos serão aceitos até 14/05 (7 dias antes do evento) às 18h</p>
                        <p className="text-center"><NavLink className="text-center" to="/politica-de-privacidade">Politica de Privacidade</NavLink></p>
                    </div>
                </div>
            </div>
            {/* <Button onClick={() => getTicket()}>add</Button> */}
            {/* <Footer /> */}
		</>
    );
}
  
export default Cart;