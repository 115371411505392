import React, {useState, useEffect, useCallback} from 'react';
import { useNavigate } from 'react-router-dom';
import {Spinner, Form, Button} from 'react-bootstrap'
import MaskedInput from 'react-maskedinput'
import Swal from 'sweetalert2'
import utils from '../services/utils';
import '../App.scss';

import Header from '../components/header';
import Nav from '../components/nav';
import DadosIngresso from '../components/forms/dadosIngresso';
// import Footer from '../components/footer';
import { apiURL } from '../services/api';

function Register() {
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false)
    const [token, setToken] = useState();
    const [tickets, setTickets] = useState([]);
    const [escola, setEscola] = useState();
    let navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {

            var t = token
            if(token === undefined) {
                t = localStorage.getItem('TOKEN');
                setToken(t)
            }
            // get the data from the api
            const data = await apiURL.get('pedido', { headers: { Authorization: 'Bearer ' + t }})
            // convert data to json
            setTickets(
                data.data.ticket.map((x) => {
                    return { ...x };
                })
            );
            setEscola(data.data.escola);
            // const json = await data.json();
            // return json;
        }

        fetchData()
            .catch(console.error);
    }, [])

    const handleSubmit = useCallback(async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            setLoading(true)
            let send = true;
            let validarcnpj = false;
            validarcnpj = utils.validarCNPJ(form.cnpj.value);
            if(!validarcnpj) {
                var msg = 'CNPJ inválido';
                send = false;
                setLoading(false)
                Swal.fire({
                    title: 'Ops!',
                    text: msg,
                    icon: 'error',
                    confirmButtonText: 'Fechar'
                })
            }
            if(send) {
                let pessoas = [];
                if(form.nome.length === undefined) {
                    let p = {
                        "idProduto": form.idProduto.value,
                        "nome": form.nome.value,
                        "cargo": form.cargo.value,
                        "email": form.email.value,
                        "telefone": form.telefone.value,
                        "cidade": form.cidade.value,
                        "uf": form.uf.value,
                    }
                    pessoas.push(p)
                } else {
                    for (let i = 0; i < form.nome.length; i++) {
                        let p = {
                            "idProduto": form.idProduto[i].value,
                            "nome": form.nome[i].value,
                            "cargo": form.cargo[i].value,
                            "email": form.email[i].value,
                            "telefone": form.telefone[i].value,
                            "cidade": form.cidade[i].value,
                            "uf": form.uf[i].value,
                        }
                        pessoas.push(p)
                    }
                }
                var idEscola = JSON.parse(localStorage.getItem('ESCOLA'))
                let dados = {
                    "codigo": idEscola.codigo,
                    "idEscola": idEscola.id,
                    "cnpj": form.cnpj?.value,
                    "escola": form.escola?.value,
                    "cidadeEscola": form.cidadeEscola?.value,
                    "estadoEscola": form.ufEscola?.value,
                    "pessoas": pessoas
                }
                await apiURL.post('pedido', dados, { headers: { Authorization: 'Bearer ' + token }})
                .then(function (response) {
                    if(response.data.success) {
                        localStorage.setItem('PEDIDO_COMPRA',response.data.pedido)
                        if(response.data.cortesia === 0)
                            navigate('/checkout');
                        else
                            navigate('/order');
                        setLoading(false)
                    } else {
                        setLoading(false)
                        Swal.fire({
                            title: 'Ops!',
                            text: response.data.message,
                            icon: 'info',
                            confirmButtonText: 'Fechar'
                        })
                    }
                })
                .catch(function (error) {
                    setLoading(false)
                    Swal.fire({
                        title: 'Ops!',
                        text: 'Algo deu errado tente novamente',
                        icon: 'info',
                        confirmButtonText: 'Fechar'
                    })
                })
            }
        }
        setValidated(true);
    }, [token])


    return (
		<>
            <div className="container main">
                <Header />
                <Nav active="Dados Pessoais" />
                <div className="cart row p-5">
                    {
                        tickets.length > 0 ?
                        <Form method="post" noValidate onSubmit={handleSubmit} validated={validated} autoComplete="off">
                            <h3 className="title">Dados da Escola</h3>
                            <div className="row">
                                <div className="col-xl-6 col-12">
                                    <div className="mb-3">
                                        <label className="form-label">CNPJ</label>
                                        <MaskedInput className="form-control" mask="11.111.111/1111-11" name="cnpj" required value={escola?.cnpj} disabled/>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-12">
                                    <div className="mb-3">
                                        <label htmlFor="exampleFormControlRazaoSocial" className="form-label">Nome da Escola (Nome Fantasia)</label>
                                        <input required type="text" name="escola" className="form-control" id="exampleFormControlRazaoSocial" defaultValue={escola?.nome} disabled/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-6 col-12">
                                    <div className="mb-3">
                                        <label htmlFor="exampleFormControlCidadeEscola" className="form-label">Cidade</label>
                                        <input required type="text" name="cidadeEscola" className="form-control cidade" id="exampleFormControlCidadeEscola" defaultValue={escola?.cidade} disabled />
                                    </div>
                                </div>
                                <div className="col-xl-6 col-12">
                                    <div className="mb-3">
                                        <label htmlFor="exampleFormControlEstadoEscola" className="form-label">Estado</label>
                                        <select name="ufEscola" id="exampleFormControlEstadoEscola" className="form-control" required value={escola?.estado} disabled>
                                            <option value=""></option>
                                            <option value="AC">Acre</option>
                                            <option value="AL">Alagoas</option>
                                            <option value="AP">Amapá</option>
                                            <option value="AM">Amazonas</option>
                                            <option value="BA">Bahia</option>
                                            <option value="CE">Ceará</option>
                                            <option value="DF">Distrito Federal</option>
                                            <option value="ES">Espírito Santo</option>
                                            <option value="GO">Goiás</option>
                                            <option value="MA">Maranhão</option>
                                            <option value="MT">Mato Grosso</option>
                                            <option value="MS">Mato Grosso do Sul</option>
                                            <option value="MG">Minas Gerais</option>
                                            <option value="PA">Pará</option>
                                            <option value="PB">Paraíba</option>
                                            <option value="PR">Paraná</option>
                                            <option value="PE">Pernambuco</option>
                                            <option value="PI">Piauí</option>
                                            <option value="RJ">Rio de Janeiro</option>
                                            <option value="RN">Rio Grande do Norte</option>
                                            <option value="RS">Rio Grande do Sul</option>
                                            <option value="RO">Rondônia</option>
                                            <option value="RR">Roraima</option>
                                            <option value="SC">Santa Catarina</option>
                                            <option value="SP">São Paulo</option>
                                            <option value="SE">Sergipe</option>
                                            <option value="TO">Tocantins</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {
                                tickets.length > 0 &&
                                <>
                                    <h3 className="title mt-4">Dados Pessoais</h3>
                                    {
                                        tickets.map((ticket, index) => {
                                            return (
                                                <DadosIngresso key={index} ticket={ticket} />
                                            )
                                        })
                                    }
                                </>
                            }
                            <hr />
                            <div className="row">
                                <div className="col-12 d-flex justify-content-end">
                                    <button disabled={loading} type="submit" name="salvar" className="btn btn-primary b-radius-3 mb-3 btnFinalizar d-flex align-items-center">
                                        {
                                            loading &&
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                className="me-2"
                                            />
                                        }
                                        Continuar para pagamento
                                    </button>
                                </div>
                            </div>
                        </Form>
                        :
                        <Button className="btn btn-primary b-radius-3 mb-3 btnFinalizar" onClick={() => navigate('/checkout')}>Continuar para pagamento</Button>
                    }
                </div>
            </div>
		</>
    );
}
  
export default Register;