import React, {useState, useEffect, useCallback} from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import {Button, Form, Spinner} from 'react-bootstrap';
import Swal from 'sweetalert2'
import MaskedInput from 'react-maskedinput'
import './style.scss';

import { apiURL } from '../../services/api';

function FormCamas({item, id, variavel = 'Hóspede', camas, handle}) {
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false)
    const [qtd, setQTD] = useState(1)
    const [cliente, setCliente] = useState()

    return (
        <>
            <h6 className="title mt-4">{variavel} {item}</h6>
            <input type="hidden" name="idProduto" value={id} />
            <div className="row">
                <div className="col-xl-6 col-12">
                    <div className="mb-3">
                        <label htmlFor="exampleFormControlNome" className="form-label">Nome Completo</label>
                        <input required type="text" name="nome" className="form-control" id="exampleFormControlNome" />
                    </div>
                </div>
                <div className="col-xl-6 col-12">
                    <div className="mb-3">
                        <label htmlFor="exampleFormControlCargo" className="form-label">Cargo</label>
                        <input required type="text" name="cargo" className="form-control" id="exampleFormControlCargo" />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-6 col-12">
                    <div className="mb-3">
                        <label htmlFor="exampleFormControlEmail" className="form-label">E-mail</label>
                        <input required type="email" name="email" className="form-control" id="exampleFormControlEmail" />
                    </div>
                </div>
                <div className="col-xl-6 col-12">
                    <div className="mb-3">
                        <label htmlFor="exampleFormControlRG" className="form-label">Celular</label>
                        <MaskedInput className="form-control" mask="(11) 11111-1111" name="telefone" required />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-6 col-12">
                    <div className="mb-3">
                        <label htmlFor="exampleFormControlCidade" className="form-label">Cidade</label>
                        <input required type="text" name="cidade" className="form-control cidade" id="exampleFormControlCidade" />
                    </div>
                </div>
                <div className="col-xl-6 col-12">
                    <div className="mb-3">
                        <label htmlFor="exampleFormControlEstado" className="form-label">Estado</label>
                        <select name="uf" id="exampleFormControlEstado" className="form-control" required >
                            <option value=""></option>
                            <option value="AC">Acre</option>
                            <option value="AL">Alagoas</option>
                            <option value="AP">Amapá</option>
                            <option value="AM">Amazonas</option>
                            <option value="BA">Bahia</option>
                            <option value="CE">Ceará</option>
                            <option value="DF">Distrito Federal</option>
                            <option value="ES">Espírito Santo</option>
                            <option value="GO">Goiás</option>
                            <option value="MA">Maranhão</option>
                            <option value="MT">Mato Grosso</option>
                            <option value="MS">Mato Grosso do Sul</option>
                            <option value="MG">Minas Gerais</option>
                            <option value="PA">Pará</option>
                            <option value="PB">Paraíba</option>
                            <option value="PR">Paraná</option>
                            <option value="PE">Pernambuco</option>
                            <option value="PI">Piauí</option>
                            <option value="RJ">Rio de Janeiro</option>
                            <option value="RN">Rio Grande do Norte</option>
                            <option value="RS">Rio Grande do Sul</option>
                            <option value="RO">Rondônia</option>
                            <option value="RR">Roraima</option>
                            <option value="SC">Santa Catarina</option>
                            <option value="SP">São Paulo</option>
                            <option value="SE">Sergipe</option>
                            <option value="TO">Tocantins</option>
                        </select>
                    </div>
                </div>
            </div>
        </>
    );
}
  
export default FormCamas;