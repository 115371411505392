import React, {useState, useEffect, useCallback} from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import {Spinner, Form} from 'react-bootstrap';
import Swal from 'sweetalert2'

import { apiURL } from '../../../services/api';
import './style.scss';

function Cupom({handle}) {
    const [loading, setLoading] = useState(false)
    const [validated, setValidated] = useState(false);
    const [cupom, setCupom] = useState();

    const handleSubmit = useCallback(async (event) => {

        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {

            setLoading(true);
            await apiURL.get('cupom?cupom='+form.cupom.value)
            .then(function (response) {
                if(response.data.success) {
                    Swal.fire({
                        title: 'Cupom',
                        text: response.data.message,
                        icon: 'success',
                        confirmButtonText: 'Ok',
                    })
                    setCupom(response.data.cupom)
                    handle(response.data.cupom)
                    setLoading(false)
                } else {
                    setLoading(false)
                    Swal.fire({
                        title: 'Ops!',
                        text: response.data.message,
                        icon: 'error',
                        confirmButtonText: 'Fechar'
                    })
                    setValidated(false);
                }
            })
            .catch(function (error) {
                setLoading(false)
                Swal.fire({
                    title: 'Ops!',
                    text: 'Algo deu errado tente novamente',
                    icon: 'info',
                    confirmButtonText: 'Fechar'
                })
                setValidated(false);
            })
        }
        // setValidated(true);
    }, [handle])

    return (
		<>
			<div className="cupom row">
				<div className="col-12">
                    <Form method="post" noValidate onSubmit={handleSubmit} validated={validated} autoComplete="off">
                        <div className="row">
                            <div className="col-xl-12 col-12">
                                <div className="mb-3">
                                    <label htmlFor="exampleFormControlCupom" className="form-label">Cupom</label>
                                    <input required type="text" name="cupom" className="form-control" id="exampleFormControlCupom"/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 d-flex justify-content-end">
                                <button disabled={loading} type="submit" name="salvar" className="btn btn-primary b-radius-3 mb-3 btnFinalizar d-flex align-items-center">
                                    {
                                        loading &&
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            className="me-2"
                                        />
                                    }
                                    Validar Cupom
                                </button>
                            </div>
                        </div>
                    </Form>
				</div>
			</div>
		</>
    );
}
  
export default Cupom;