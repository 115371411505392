import React, {useState, useEffect, useCallback} from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import {Button, Form, Spinner} from 'react-bootstrap';
import Swal from 'sweetalert2'
import './style.scss';

import FromIngresso from './formIngresso';

import { apiURL } from '../../services/api';

function DadosIngresso({ticket, handle}) {
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false)
    const [qtd, setQTD] = useState([])

    useEffect(() => {
        var q = []
        for (let i = 1; i <= parseInt(ticket.qtd); i++) {
            q.push(i);
        }
        setQTD(q);
    }, [ticket])

    return (
		<>
			<h4 className="title mt-4">{ticket.nome}</h4>
            {
                qtd.length > 0 &&
                <>
                    {
                        qtd.map((item, index) => {
                            return (
                                <FromIngresso key={index} item={item} id={ticket.id} ticket={ticket} />
                            )
                        })
                    }
                </>
            }
		</>
    );
}
  
export default DadosIngresso;