import React, {useEffect} from 'react';
import '../App.scss';

function Redirect() {

    useEffect(() => {
        window.location.href = 'https://www.convencaoanglo2023.com.br';
    }, [])

    return (
		<>
            <p className="text-center">Convenção Anglo 2023</p>
		</>
    );
}
  
export default Redirect;