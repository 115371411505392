import React, {useState, useEffect} from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import './style.scss';

function Nav({active}) {
    let navigate = useNavigate();

    return (
		<>
            <div className="focused-checkout-header pb">
                <div className="checkout-page-title page-title">
                    <div className="page-title-inner d-flex flex-row container">
                        <div className="d-flex flex-col flex-grow justify-content-center navigation">
                            <nav className="breadcrumbs d-flex checkout-breadcrumbs text-center align-items-center">
                                <a href="#" className={`${(active === 'Carrinho de Compras' && "current")}`}>Carrinho de Compras</a>
                                <span className="divider hide-for-small">
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </span>
                                <a href="#" className={`${(active === 'Dados Pessoais' && "current")}`}>Dados Pessoais</a>
                                <span className="divider hide-for-small">
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </span>
                                <a href="#" className={`${(active === 'Dados de Pagamento' && "current")}`}>Dados de Pagamento</a>
                                <span className="divider hide-for-small">
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </span>
                                <a href="#" className={`${(active === 'Pedido Completo' && "current")}`}>Pedido Completo</a>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
		</>
    );
}
  
export default Nav;