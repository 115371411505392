import React from 'react';
import {
	BrowserRouter as Router,
	Routes,
	Route,
} from "react-router-dom";
import './App.scss';

import Redirect from "./pages/redirect";
import Cart from "./pages/cart";
import Register from "./pages/register";
import Checkout from "./pages/checkout";
import Order from "./pages/order";
import Politica from "./pages/politica";

function App() {
	return (
		<div className="background">
			<div>
				<Router>
					<Routes>
						{/* <Route exact path="/whitelist/:link" component={Whitelist} /> */}
						<Route path="/" element={<Redirect />} />
						<Route path="/cart" element={<Cart />} />
						<Route path="/cart/:ingresso" element={<Cart />} />
						<Route path="/register" element={<Register />} />
						<Route path="/checkout" element={<Checkout />} />
						<Route path="/order" element={<Order />} />
						<Route path="/politica-de-privacidade" element={<Politica />} />
						{/* <Route path="/materialdidatico" element={<Kit />} />
						<Route path="/cadastro" element={<Cadastro />} />
						<Route path="/pagamento" element={<Pagamento />} />
						<Route path="/agendamento" element={<Agendamento />} />
						<Route path="/analise" element={<Analise />} /> */}
						<Route path="*" element={<Redirect />} />
					</Routes>
				</Router>
			</div>
		</div>
	);
}

export default App;
