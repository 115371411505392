import React, {useState, useEffect, useCallback} from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import {Button, Form, Spinner} from 'react-bootstrap';
import Swal from 'sweetalert2'
import MaskedInput from 'react-maskedinput'
import './style.scss';

import FormCamas from './formCamas';
import { apiURL } from '../../services/api';

function FromIngresso({item, id, ticket, handle}) {
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false)
    const [qtd, setQTD] = useState(1)
    const [cliente, setCliente] = useState()

    useEffect(() => {
        var c = []
        for (let i = 1; i <= parseInt(ticket.camas); i++) {
            c.push(i);
        }
        setQTD(c);
    }, [ticket])

    return (
        <>
            <h5 className="title mt-4">Ingresso {item}</h5>
            {
                qtd.length > 0 &&
                <>
                    {
                        qtd.map((item, index) => {
                            return (
                                <FormCamas key={index} item={item} id={ticket.id} camas={ticket.camas} variavel={ticket.variavel} />
                            )
                        })
                    }
                </>
            }
        </>
    );
}
  
export default FromIngresso;