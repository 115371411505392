import React, {useState, useEffect, useCallback} from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import {Spinner, Button} from 'react-bootstrap'
import Swal from 'sweetalert2'
import { v4 as uuid } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import '../App.scss';

function Politica() {

    return (
		<>
            <div className="container main">
                <p className="d-flex align-items-center backSite">
                    <FontAwesomeIcon icon={faChevronLeft} className=" mt-4" />
                    <a className="ms-2 mt-4" href="/cart">Voltar para o carrinho</a>
                </p>
                <h4>Política de Privacidade</h4>
                <p>Como toda a organização do Século XXI, a CONVENÇÃO ANGLO 2023 lida com informações sobre a forma de dados. Muitos desses dados dizem respeito a características pessoais de seus coordenadores de projetos, alunos, colaboradores, fornecedores, professores e demais stakeholders que gravitam em torno da CONVENÇÃO ANGLO 2023.</p>
                <p>Você tem o direito fundamental de ter sua privacidade protegida, a CONVENÇÃO ANGLO 2023 está ciente disso e envida os melhores esforços na proteção de seus dados pessoais.</p>
                <p>As Plataformas da CONVENÇÃO ANGLO 2023 compreendem os meios eletrônicos de responsabilidade da CONVENÇÃO ANGLO 2023 – por ela iniciados, contratados e alimentados de conteúdo –, tais como o website oficial e os perfis em redes sociais.</p>
                <p>A CONVENÇÃO ANGLO 2023 assegura que seus dados pessoais:</p>
                <ul>
                    <li>a) serão obtidos mediante seu prévio, expresso e informado consentimento;</li>
                    <li>b) serão obtidos na exata e estrita extensão necessária para o atingimento das finalidades em comum acordo estabelecidas;</li>
                    <li>c) não serão compartilhados com terceiros, salvo seu específico consentimento;</li>
                    <li>d) serão utilizados única e exclusivamente nos âmbitos da prestação de serviços educacionais e acadêmicos;</li>
                    <li>e) não serão transferidos internacionalmente, exceto no caso de cursos em que haja intercâmbio ou módulos internacionais, ainda assim mediante prévio, expresso e informado consentimento do titular;</li>
                    <li>f) não serão modificados, perdidos ou deteriorados. Qualquer atividade de perfilhamento atenderá exclusivamente o escopo da melhor prestação de serviços educacionais, sempre na extensão de seu consentimento.</li>
                </ul>
                <p>Seus dados pessoais poderão, a qualquer momento, ser solicitados por você. Os direitos do titular, previstos nos artigos 17 a 20 da Lei nº 13.709/2018, da Lei Geral de Proteção de Dados (“LGPD”), poderão ser exercidos a qualquer tempo e sua concretização será providenciada pelos responsáveis da CONVENÇÃO ANGLO 2023  com a maior brevidade possível.</p>
                <p>Seus dados pessoais serão processados dentro de um ambiente protegido por segurança da informação. Qualquer incidente de segurança – entendido tal como qualquer evento que possa implicar perda total ou parcial, compartilhamento não autorizado, replicação não autorizada, acesso não permitido a dado(s) pessoal(is) – envolvendo seus dados pessoais será prontamente informado a você, sendo certo que a CONVENÇÃO ANGLO 2023 tomará todas as cautelas para que esses incidentes não ocorram.</p>
                <p>A CONVENÇÃO ANGLO 2023 assegura que sua experiência com dados pessoais nas plataformas por ela mantidas sejam as mais seguras possíveis.</p>
                <p>A CONVENÇÃO ANGLO 2023 espera reciprocidade. Dessa forma, o nome da CONVENÇÃO ANGLO 2023, suas instalações, coordenadores de projetos, alunos, colaboradores, professores e demais stakeholders devem também ter sua privacidade preservada. Assim, publicações em redes sociais, compartilhamento de imagens, áudio, vídeo, divulgação de nomes e informações que possam identificar você e qualquer pessoa gerarão responsabilidade aos que tomaram parte de tais atos, não cumprindo a CONVENÇÃO ANGLO 2023 realizar a vigilância nesse nível de privacidade.</p>
                <p>Toda informação fornecida será mantida em sigilo e será utilizada no estabelecimento do relacionamento entre a instituição e os usuários das plataformas da CONVENÇÃO ANGLO 2023. Por questões de segurança, ressaltamos que não serão tratadas ou retidas informações, tais como número de cartão de crédito, conta e senha bancária, nem serão enviadas comunicações, por qualquer meio eletrônico através do portal, solicitando esse tipo de dado.</p>
                <p>O usuário é responsável pelas informações pessoais inseridas nas Plataformas da CONVENÇÃO ANGLO 2023, e poderá consentir em receber informações institucionais da CONVENÇÃO ANGLO 2023 em qualquer um dos canais cadastrados no portal, podendo cancelar o recebimento deste conteúdo caso não seja mais de seu interesse. A Fundação se compromete a não divulgar estes dados para terceiros, exceção feita a casos em que pese a força da lei. Nenhuma empresa está autorizada a enviar mensagens aos usuários cadastrados pelo portal, em nome da CONVENÇÃO ANGLO 2023.</p>
                <p>Os e-mails enviados pela CONVENÇÃO ANGLO 2023 sempre serão identificados com a extensão @convencaoanglo2023.com.br; sempre conterão título correlato com as atividades da CONVENÇÃO ANGLO 2023 e poderão conter anexos. Em caso de dúvida sobre a autenticidade do e-mail, você deverá contatar a Central de Serviços da CONVENÇÃO ANGLO 2023.</p>
                <p>Outras comunicações por quaisquer meios que você receba em nome da CONVENÇÃO ANGLO 2023, e das quais você desconfiar da veracidade, ou mesmo não as deseja mais receber, você deverá entrar em contato com a Central de Serviços da CONVENÇÃO ANGLO 2023 para os procedimentos cabíveis.</p>
                <p>A CONVENÇÃO ANGLO 2023 não publicará qualquer texto, foto ou vídeo sem seu consentimento prévio, expresso e informado.</p>
                <p>Os materiais contidos nas Plataformas da CONVENÇÃO ANGLO 2023 não poderão ser usados para fins comerciais sem prévia autorização.</p>
                <p>A CONVENÇÃO ANGLO 2023 não será responsabilizada caso você divulgue seus dados pessoais e informações privadas para terceiros, ainda que dentro do ambiente físico ou das Plataformas da CONVENÇÃO ANGLO 2023.</p>
                <p>A CONVENÇÃO ANGLO 2023 tem o direito de interromper, suspender ou terminar o uso das Plataformas. Quaisquer programas, softwares, aplicativos da CONVENÇÃO ANGLO 2023 que sejam disponibilizados a você serão utilizados de acordo com seus respectivos Termos de Uso, vedada sua replicação, no todo ou em parte.</p>
                <p>Caso você seja responsável pela contrariedade a esta Política de Privacidade ou dê causa, direta ou indiretamente, a um incidente de segurança, seu caso será encaminhado à Coordenação responsável pelo seu programa, que decidirá sobre sua continuidade na CONVENÇÃO ANGLO 2023, sem prejuízo de sua responsabilidade civil, penal e administrativa.</p>
            </div>
		</>
    );
}
  
export default Politica;