import React, {useState, useEffect, useCallback} from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import {Button, Form, Spinner} from 'react-bootstrap';
import Swal from 'sweetalert2'
import './style.scss';

import { apiURL } from '../../services/api';

function CodigoEscola({school, handle}) {
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false)
    const [escola, setEscola] = useState()

    const handleCodigo = useCallback(async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            if (school === undefined) {
                getEscola()
            } else {
                if(form.codigo.value !== school.codigo) {
                    Swal.fire({
                        title: 'Código alterado!',
                        text: 'Deseja realmente alterar a escola?',
                        icon: 'info',
                        showDenyButton: true,
                        confirmButtonText: 'Sim',
                        denyButtonText: 'Não',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            getEscola()
                        }
                    })
                }
            }
            
            async function getEscola() {
                setLoading(true)
                var t = localStorage.getItem('TOKEN');
                await apiURL.get('escola?codigo='+form.codigo.value, { headers: { Authorization: 'Bearer ' + t }})
                .then(function (response) {
                    if(response.data.success) {
                        setEscola(response.data.escola)
                        Swal.fire({
                            title: 'Escola encontrada!',
                            text: response.data.escola.nome,
                            icon: 'success',
                            confirmButtonText: 'Fechar'
                        }).then((result) => {
                            localStorage.setItem("ESCOLA", JSON.stringify(response.data.escola))
                            let p = 0;
                            if(!response.data.escola.pago) {
                                Swal.fire({
                                    title: 'Pedido Encontrado!',
                                    text: 'Existe um pedido não pagos para esta escola deseja pagar?',
                                    icon: 'info',
                                    confirmButtonText: 'Sim, pagar',
                                    showDenyButton: true,
                                    showCloseButton: true,
                                    showCancelButton: true,
                                    confirmButtonColor: '#28a745',
                                    denyButtonText: 'Não, excluir esse pedido',
                                    cancelButtonText: 'Fechar e continuar novo pedido',
                                    // closeButtonText: 'Fechar e continuar novo pedido',
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        p = 1;
                                    } else if(result.isDenied) {
                                        p = 2;
                                        setTimeout(() => {
                                            apiURL.get('escola?codigo='+form.codigo.value, { headers: { Authorization: 'Bearer ' + t }})
                                            .then(function (response) {
                                                if(response.data.success) {
                                                    setEscola(response.data.escola)
                                                    handle(response.data.escola, 0)
                                                }
                                            })
                                        }, 3000);
                                    }
                                    handle(response.data.escola, p)
                                })
                            } else {
                                handle(response.data.escola, p)
                            }
                        })
                        setLoading(false)
                    } else {
                        setLoading(false)
                        Swal.fire({
                            title: 'Ops!',
                            text: response.data.message,
                            icon: 'info',
                            confirmButtonText: 'Fechar'
                        })
                    }
                })
                .catch(function (error) {
                    setLoading(false)
                    Swal.fire({
                        title: 'Ops!',
                        text: 'Algo deu errado tente novamente',
                        icon: 'info',
                        confirmButtonText: 'Fechar'
                    })
                })
            }
        }
        setValidated(true);
    },[handle, school])

    useEffect(() => {
        if (school !== undefined) {
            setEscola(school)
        }
    },[escola, school])

    return (
		<>
			<div className="codigoEscola row pe-5 ps-5">
				<div className="col-12 divisor">
                    <Form className="d-flex formEscola" noValidate onSubmit={handleCodigo} validated={validated} autoComplete="off">
                        <Form.Label className="mb-0 me-3"><strong>Digite o Código da Sua Escola</strong></Form.Label>
                        <Form.Control type="text" className="me-3" required name="codigo" />
                        <Button 
                            variant="primary"
                            type="submit"
                            // disabled={loading}
                        >
                            {
                                loading &&
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className="me-2"
                                />
                            }
                            Validar Código
                        </Button>
                    </Form>
                    {
                        (escola?.nome !== '' && escola?.nome !== undefined) &&
                        <>
                            <div className="mt-4 dadosEscola">
                                <span className="me-3"><strong>Escola:</strong> {escola?.nome}</span>
                                <span className="me-3"><strong>Ingressos Disponíveis:</strong> {escola?.disponiveis}</span>
                                <span className="me-3"><strong>Ingressos Comprados:</strong> {escola?.usados}</span>
                            </div>
                        </>
                    }
				</div>
			</div>
		</>
    );
}
  
export default CodigoEscola;