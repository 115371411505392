import React, {useState, useEffect, useCallback} from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import {Spinner, Form, Tab, Tabs, Button} from 'react-bootstrap'
import MaskedInput from 'react-maskedinput'
import Swal from 'sweetalert2'
import QRCode from "react-qr-code";
import utils from '../services/utils';
import '../App.scss';

import Header from '../components/header';
import Nav from '../components/nav';
import * as Produtos from '../components/produtos';
// import Footer from '../components/footer';
import { apiURL } from '../services/api';

function Checkout() {
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false)
    const [qrCode, setQRCode] = useState('')
    const [qrCodeExpirado, setQRCodeExpirado] = useState(false)
    const [total, setTotal] = useState(0)
    const [activeTab, setActiveTab] = useState('credit')
	const [escola, setEscola] = useState()
	const [pedido, setPedido] = useState()
	const [token, setToken] = useState()
	const [cupom, setCupom] = useState()
	const [tickets, setTickets] = useState([])
    let navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {

            var t = token
            var e = escola
            var p = pedido
            if(token === undefined) {
                t = localStorage.getItem('TOKEN');
                p = localStorage.getItem('PEDIDO_COMPRA');
                e = JSON.parse(localStorage.getItem('ESCOLA'));
                setToken(t)
                setEscola(e)
                setPedido(p)
            }
            // get the data from the api
            const data = await apiURL.get('pedido?pedido='+p, { headers: { Authorization: 'Bearer ' + t }})
            // convert data to json
            setTickets(
                data.data.ticket.map((x) => {
                    return { ...x };
                })
            );
            setTotal(data.data.total);
            if(data.data?.cupom !== null)
                setCupom(data.data?.cupom);
            // const json = await data.json();
            // return json;
        }

        fetchData()
            .catch(console.error);
    }, [token, escola, pedido])

    const handleSubmitCredito = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            setLoading(true)
            let dados = {
                "numero": form.numero.value,
                "nome": form.nome.value,
                "validade": form.validade.value,
                "codigo": form.codigo.value,
                "parcelas": form.parcelas.value,
                "type": 1,
                "pedido": localStorage.getItem('PEDIDO_COMPRA')
            }
            await apiURL.post('pagamento', dados)
            .then(function (response) {
                if(response.data.success) {
                    Swal.fire({
                        title: 'Pagamento!',
                        text: 'Concluído com Sucesso',
                        icon: 'success',
                        confirmButtonText: 'Fechar'
                    }).then((result) => {
                        navigate('/order');
                    })
                    setLoading(false)
                } else {
                    setLoading(false)
                    Swal.fire({
                        title: 'Pagamento Recusado!',
                        text: response.data.message,
                        icon: 'error',
                        confirmButtonText: 'Fechar'
                    })
                }
            })
            .catch(function (error) {
                setLoading(false)
                Swal.fire({
                    title: 'Ops!',
                    text: 'Algo deu errado tente novamente',
                    icon: 'info',
                    confirmButtonText: 'Fechar'
                })
            })
        }
        setValidated(true);
    };

    const handleSubmitDebito = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            setLoading(true)
            let dados = {
                "numero": form.numero_debito.value,
                "nome": form.nome_debito.value,
                "validade": form.validade_debito.value,
                "codigo": form.codigo_debito.value,
                "type": 2,
                "pedido": localStorage.getItem('PEDIDO_COMPRA')
            }
            await apiURL.post('pagamento', dados)
            .then(function (response) {
                if(response.data.success) {
                    Swal.fire({
                        title: 'Pagamento!',
                        text: 'Concluído com Sucesso',
                        icon: 'success',
                        confirmButtonText: 'Fechar'
                    }).then((result) => {
                        navigate('/order');
                    })
                    setLoading(false)
                } else {
                    setLoading(false)
                    Swal.fire({
                        title: 'Pagamento Recusado!',
                        text: response.data.message,
                        icon: 'error',
                        confirmButtonText: 'Fechar'
                    })
                }
            })
            .catch(function (error) {
                setLoading(false)
                console.log(error.response.data)
                Swal.fire({
                    title: 'Ops!',
                    text: 'Algo deu errado tente novamente',
                    icon: 'info',
                    confirmButtonText: 'Fechar'
                })
            })
        }
        setValidated(true);
    }

    const handleSubmitPix = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        setQRCode('')
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            setLoading(true)
            let dados = {
                "type": 3,
                "pedido": localStorage.getItem('PEDIDO_COMPRA')
            }
            await apiURL.post('pagamento', dados)
            .then(function (response) {
                if(response.data.success) {
                    if(response.data.status === 'WAITING') {
                        setQRCode(response.data.qrcode);
                        setQRCodeExpirado(false)
                        setTimeout(() => {
                            setQRCode('')
                            setQRCodeExpirado(true)
                        }, 120000);
                    } else if(response.data.status === 'DENY' || response.data.message === 'DENY') {
                        Swal.fire({
                            title: 'Erro!',
                            text: response.data.message,
                            icon: 'warning',
                            confirmButtonText: 'Fechar'
                        })
                    } else {
                        Swal.fire({
                            title: 'Erro!',
                            text: 'Algo deu errado, tente novamente',
                            icon: 'warning',
                            confirmButtonText: 'Fechar'
                        })
                    }
                    setLoading(false)
                } else {
                    setLoading(false)
                    Swal.fire({
                        title: 'Ops!',
                        text: 'Algo deu errado tente novamente',
                        icon: 'info',
                        confirmButtonText: 'Fechar'
                    })
                }
            })
            .catch(function (error) {
                setLoading(false)
                console.log(error.response.data)
                Swal.fire({
                    title: 'Ops!',
                    text: 'Algo deu errado tente novamente',
                    icon: 'info',
                    confirmButtonText: 'Fechar'
                })
            })
        }
        setValidated(true);
    }
    
    const handleSubmitBoleto = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        setQRCode('')
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            // setLoading(true)
            let dados = {
                "type": 4,
                "pedido": localStorage.getItem('PEDIDO_COMPRA')
            }
            await apiURL.post('pagamento', dados)
            .then(function (response) {
                // if(response.data.success) {
                //     if(response.data.status === 'WAITING') {
                //         setQRCode(response.data.qrcode);
                //         setQRCodeExpirado(false)
                //         setTimeout(() => {
                //             setQRCode('')
                //             setQRCodeExpirado(true)
                //         }, 120000);
                //     } else if(response.data.status === 'DENY' || response.data.message === 'DENY') {
                //         Swal.fire({
                //             title: 'Erro!',
                //             text: response.data.message,
                //             icon: 'warning',
                //             confirmButtonText: 'Fechar'
                //         })
                //     } else {
                //         Swal.fire({
                //             title: 'Erro!',
                //             text: 'Algo deu errado, tente novamente',
                //             icon: 'warning',
                //             confirmButtonText: 'Fechar'
                //         })
                //     }
                //     setLoading(false)
                // } else {
                //     setLoading(false)
                //     Swal.fire({
                //         title: 'Ops!',
                //         text: 'Algo deu errado tente novamente',
                //         icon: 'info',
                //         confirmButtonText: 'Fechar'
                //     })
                // }
            })
            .catch(function (error) {
                setLoading(false)
                console.log(error.response.data)
                Swal.fire({
                    title: 'Ops!',
                    text: 'Algo deu errado tente novamente',
                    icon: 'info',
                    confirmButtonText: 'Fechar'
                })
            })
        }
        setValidated(true);
    }

    const handleTab = useCallback((eventKey) => {
        setActiveTab(eventKey);
        if(eventKey === 'credit') {
            // setAvista(false)
            // setValorKit('kit')
        } else {
            // setAvista(true)
            // setValorKit('kit_avista')
        }
    },[])

    const changeParcelas = useCallback((eventKey) => {
        if(eventKey === '1') {
            // setValorKit('kit_avista')
            // setAvista(true)
        } else {
            // setValorKit('kit')
            // setAvista(false)
        }
    },[])

    return (
		<>
            <div className="container main">
                <Header />
                <Nav active="Dados de Pagamento" />
                <div className="container-fluid internalPage p-5">
                    <div className="row">
                        <div className="col-12 content1200">
                            {
                                tickets.length > 0 &&
                                <div className="cart row p-lg-5">
                                    <div className="produtos noBorder col-12">
                                        <Produtos.Header />
                                        {
                                            tickets.map((ticket, index) => {
                                                return (
                                                    <Produtos.Produto key={index} index={index} item={ticket} edit={false} />
                                                )
                                            })
                                        }
                                    </div>
                                    <hr />
                                    <div className="resumoCart col-12">
                                        <Produtos.Resumo tickets={tickets} cupom={cupom} />
                                    </div>
                                </div>
                            }
                            <h2 className="text-center mt-2 mb-4">ESCOLHA A FORMA DE PAGAMENTO E INFORME OS DADOS ABAIXO PARA FINALIZAR A COMPRA</h2>
                            <Tabs
                                defaultActiveKey="credito"
                                id="uncontrolled-tab-example"
                                className="mb-3"
                                onSelect={(event) => handleTab(event)}
                            >
                                <Tab eventKey="credito" title="Crédito">
                                    <Form method="post" onSubmit={handleSubmitCredito} validated={validated} autoComplete="off">
                                        <div className="row">
                                            <div className="col-xl-6 col-12">
                                                <div className="mb-3">
                                                    <label className="form-label">Número do Cartão</label>
                                                    <MaskedInput className="form-control" mask="1111 1111 1111 1111" name="numero" required/>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-12">
                                                <div className="mb-3">
                                                    <label htmlFor="exampleFormControlNome" className="form-label">Nome do Titular do Cartão</label>
                                                    <input required type="text" name="nome" className="form-control" id="exampleFormControlNome" placeholder={'Digite exatamente igual ao impresso no cartão'} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xl-6 col-12">
                                                <div className="mb-3">
                                                    <label htmlFor="exampleFormControlEmail" className="form-label">Validade</label>
                                                    <MaskedInput className="form-control" mask="11/11" name="validade" required/>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-12">
                                                <div className="mb-3">
                                                    <label htmlFor="exampleFormControlRG" className="form-label">Código de Segurança</label>
                                                    <input required type="text" name="codigo" className="form-control" id="exampleFormControlRG" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="mb-3">
                                                    <label htmlFor="exampleFormControlNome" className="form-label">Número de Parcelas</label>
                                                    <select className="form-select" name="parcelas" id="parcelas" onChange={(event) => changeParcelas(event.target.value)}>
                                                        {
                                                            new Array(10).fill(0).map((parcela, index) => {
                                                                return (
                                                                    <>
                                                                        {
                                                                            index === 0 ?
                                                                            <option key={index} value={index+1}>
                                                                                {index+1} x de R$ {(total/(index+1)).toLocaleString('de-DE', {currency: 'BRL', maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                                                            </option>
                                                                            :
                                                                            <option key={index} value={index+1}>
                                                                                {index+1} x de R$ {(total/(index+1)).toLocaleString('de-DE', {currency: 'BRL', maximumFractionDigits: 2, minimumFractionDigits: 2 })} (+ juros de parcelamento)
                                                                            </option>
                                                                        }
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-12 d-flex justify-content-end">
                                                <button disabled={loading} type="submit" name="salvar" className="btn btn-primary b-radius-3 mb-3 btnFinalizar d-flex align-items-center">
                                                    {
                                                        loading &&
                                                        <Spinner
                                                            as="span"
                                                            animation="grow"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                            className="me-2"
                                                        />
                                                    }
                                                    Finalizar Compra no Crédito
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                </Tab>
                                <Tab eventKey="debito" title="Débito">
                                    <Form method="post" onSubmit={handleSubmitDebito} validated={validated} autoComplete="off">
                                        <div className="row">
                                            <div className="col-xl-6 col-12">
                                                <div className="mb-3">
                                                    <label className="form-label">Número do Cartão</label>
                                                    <MaskedInput className="form-control" mask="1111 1111 1111 1111" name="numero_debito" required/>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-12">
                                                <div className="mb-3">
                                                    <label htmlFor="exampleFormControlNome" className="form-label">Nome do Titular do Cartão</label>
                                                    <input required type="text" name="nome_debito" className="form-control" id="exampleFormControlNome" placeholder={'Digite exatamente igual ao impresso no cartão'} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xl-6 col-12">
                                                <div className="mb-3">
                                                    <label htmlFor="exampleFormControlEmail" className="form-label">Validade</label>
                                                    <MaskedInput className="form-control" mask="11/11" name="validade_debito" required/>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-12">
                                                <div className="mb-3">
                                                    <label htmlFor="exampleFormControlRG" className="form-label">Código de Segurança</label>
                                                    <input required type="text" name="codigo_debito" className="form-control" id="exampleFormControlRG" />
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-12 d-flex justify-content-end">
                                                <button disabled={loading} type="submit" name="salvar" className="btn btn-primary b-radius-3 mb-3 btnFinalizar d-flex align-items-center">
                                                    {
                                                        loading &&
                                                        <Spinner
                                                            as="span"
                                                            animation="grow"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                            className="me-2"
                                                        />
                                                    }
                                                    Finalizar Compra no Débito
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                </Tab>
                                <Tab eventKey="pix" title="Pix">
                                    {
                                        qrCode !== '' &&
                                        <div>
                                            <div className="qrcodePix">
                                                <p className="text-center"><strong>Leia o QRCode ou copie e cole o código para fazer o pagamento</strong></p>
                                                <p className="text-center">{qrCode}</p>
                                                <div className="imgQrCode">
                                                    <QRCode
                                                        size={256}
                                                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                        value={qrCode}
                                                        viewBox={`0 0 256 256`}
                                                    />
                                                </div>
                                            </div>
                                            <h5 className="text-center mt-3 qrcodePixAlert">OBS.: O QRCode tem validade de 1 minuto e 50 segundos, após esse tempo é necessário gerar um novo código para fazer o pagamento.</h5>
                                        </div>
                                    }
                                    {
                                        qrCodeExpirado &&
                                        <h5 className="text-center mt-3 qrcodePixAlert">QRCode do PIX expirou, clique no botão "Gerar QR Code" para gerar um novo.</h5>
                                    }
                                    {
                                        qrCode === '' ?
                                        <Form method="post" onSubmit={handleSubmitPix} validated={validated} autoComplete="off">
                                            <div className="row">
                                                <div className="col-12 d-flex justify-content-end">
                                                    <button disabled={loading} type="submit" name="salvar" className="btn btn-primary b-radius-3 mb-3 btnFinalizar d-flex align-items-center">
                                                        {
                                                            loading &&
                                                            <Spinner
                                                                as="span"
                                                                animation="grow"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                                className="me-2"
                                                            />
                                                        }
                                                        Gerar QR Code
                                                    </button>
                                                </div>
                                            </div>
                                        </Form>
                                        :
                                        <div className="row">
                                            <div className="col-12 d-flex justify-content-end">
                                                <button disabled={loading} type="submit" name="salvar" className="btn btn-primary b-radius-3 mb-3 btnFinalizar d-flex align-items-center" onClick={() => navigate('/order')}>
                                                    {
                                                        loading &&
                                                        <Spinner
                                                            as="span"
                                                            animation="grow"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                            className="me-2"
                                                        />
                                                    }
                                                    Já efetuei o pagamento
                                                </button>
                                            </div>
                                        </div>
                                    }
                                </Tab>
                                {/* <Tab eventKey="boleto" title="Boleto Bancário">
                                    {
                                        qrCode !== '' &&
                                        <div>
                                            <div className="qrcodePix">
                                                <QRCode
                                                    size={256}
                                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                    value={qrCode}
                                                    viewBox={`0 0 256 256`}
                                                />
                                            </div>
                                            <h5 className="text-center mt-3 qrcodePixAlert">OBS.: O QRCode tem validade de 1 minuto e 50 segundos, após esse tempo é necessário gerar um novo código para fazer o pagamento.</h5>
                                        </div>
                                    }
                                    {
                                        qrCodeExpirado &&
                                        <h5 className="text-center mt-3 qrcodePixAlert">QRCode do PIX expirou, clique no botão "Gerar QR Code" para gerar um novo.</h5>
                                    }
                                    <Form method="post" onSubmit={handleSubmitBoleto} validated={validated} autoComplete="off">
                                        <div className="row">
                                            <div className="col-12 d-flex justify-content-end">
                                                <button disabled={loading} type="submit" name="salvar" className="btn btn-primary b-radius-3 mb-3 btnFinalizar d-flex align-items-center">
                                                    {
                                                        loading &&
                                                        <Spinner
                                                            as="span"
                                                            animation="grow"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                            className="me-2"
                                                        />
                                                    }
                                                    Gerar Boleto Bancário
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                </Tab> */}
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
		</>
    );
}
  
export default Checkout;