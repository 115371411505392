import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import Utils from '../../../services/utils';
import './style.scss';

function Resumo({tickets, cupom, next = true}) {
    const [subtotal, setSubtotal] = useState(0);
    const [total, setTotal] = useState(0);
    const [desconto, setDesconto] = useState(0);
    let navigate = useNavigate();

    useEffect(() => {
        var s = 0
        tickets.forEach(element => {
            s = parseFloat(element.valor)*element.qtd + s;
        });
        setSubtotal(s)
    },[tickets])

    useEffect(() => {
        if(cupom !== undefined) {
            var d = 0
            var t = 0
            if(cupom.id_produto === 0) {
                if(cupom.tipo === '0') {
                    t = subtotal - parseFloat(cupom.valor)
                    d = -parseFloat(cupom.valor)
                    setTotal(t)
                    setDesconto(d)
                } else {
                    d = (parseFloat(cupom.valor)*parseFloat(subtotal))/100
                    t = subtotal - d
                    var dM = -parseFloat(d)
                    setDesconto(dM)
                    setTotal(t)
                }
            } else {
                tickets.forEach(element => {
                    if(element.id === cupom.id_produto) {
                        if(cupom.tipo === '0') {
                            console.log('aaaa', subtotal, parseFloat(element.valor) - parseFloat(cupom.valor));
                            t = subtotal - (parseFloat(cupom.valor) * element.qtd)
                            d = -parseFloat(cupom.valor) * element.qtd
                        } else {
                            var dM = (parseFloat(cupom.valor)*parseFloat(element.valor))/100
                            dM = dM * element.qtd
                            t = subtotal - dM
                            d = -parseFloat(dM)
                        }
                    }
                });
                setDesconto(d)
                setTotal(t)
            }
        } else {
            setTotal(subtotal)
        }
    },[cupom, subtotal])

    return (
		<>
			<div className="header row">
				<div className="col-12">
					<p><strong>TOTAL NO CARRINHO</strong></p>
                    <p className="d-flex justify-content-between">Subtotal <strong>{Utils.formatMoney(subtotal)}</strong></p>
                    <p className="d-flex justify-content-between">Desconto <strong>{Utils.formatMoney(desconto)}</strong></p>
                    <p className="d-flex justify-content-between">Total <strong>{Utils.formatMoney(total)}</strong></p>
				</div>
			</div>
		</>
    );
}
  
export default Resumo;